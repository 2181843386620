
const Headerstyle = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'fantasy',
  backgroundColor: '#e4e0d4',
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <div style={Headerstyle}>
        <h1>Welcome to The Mythic Bard</h1>
        <p>Coming Soon!</p>
      </div>
    </div>
  );
}

export default App;
